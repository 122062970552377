<template>
  <div id="secure" class="secure">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'secure',
  created: function () {
    // Used to rest Navigations and actions when the page is refreshed
    var navigationMap = this.$store.state.navigation
    var currentRoutePath = this.$route.path
    var currentNavigation = ''
    var currentAction = ''
    navigationMap.filter(function (item) {
      if (item.path === currentRoutePath) {
        currentNavigation = item.name
      }
      var navigation = item.name
      var actions = item.actions
      actions.filter(function (item) {
        if (item.path === currentRoutePath) {
          currentAction = item.name
          currentNavigation = navigation
        }
      })
    })
    if (!(currentNavigation === '' & currentAction === '')) {
      this.$store.state.selectedNavigation = currentNavigation
      this.$store.state.selectedAction = currentAction
      this.$store.state.isLogged = true
    }
  }
}
</script>
